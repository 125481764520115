'use client';

import React from 'react';
import 'swiper/css/pagination';

import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import arrow from '@/public/staticfiles/icons/arrow-right.svg';
import minion from '@/public/staticfiles/reviews/minion.webp';
import roflan from '@/public/staticfiles/reviews/roflan.webp';
import rat from '@/public/staticfiles/reviews/rat.webp';
import tryapka from '@/public/staticfiles/reviews/tryapka.webp';
import { SectionTitle } from '@/shared/ui/section-title';
import * as SC from '../styled';
import { useMediaQuery } from '@/shared/hooks';

function Reviews() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const reviewsList = [
    {
      img: minion,
      username: 'TheRAT',
      review:
        'Наконец-то нашел приложение, где могу управлять всеми моими 6 аккаунтами!!! Плюс приятный бонус – я могу легко получать доступ к любым заблокированным чатам. Кажется, я разблокировал скрытый уровень в мессенджерах.\n',
    },
    {
      img: roflan,
      username: 'Joe_d',
      review:
        "Здорово, что в приложении уже есть переводчик – не нужно никаких дополнительных приложений для общения с иностранцами. Но самое лучшее – это встроенный ИИ. Приятно иметь помощника, который помогает разобраться с моими мыслями. \uD83D\uDE04",
    },
    {
      img: rat,
      username: 'AlesiaVoice',
      review:
        "Я не собираюсь говорить, что я параноик или что-то скрываю, но чувство безопасности при использовании секретных чат-комнат и функция «двойное дно» мне нравится. ",
    },
    {
      img: tryapka,
      username: 'SandyK',
      review:
        'Я на самом деле люблю пересылать видео друзьям, и мне было приятно узнать, что в Nicegram можно отправлять не только последние видео, но и те, что я снимал ранее. Обожаю! \uD83E\uDEF6\n',
    },
  ];

  return (
    <SC.Wrapper>
      <SC.Title>
        <SectionTitle>Познакомьтесь с отзывами в нашем сообществе! </SectionTitle>
        <SC.SliderButtonsWrapper>
          <button type="button" id="reviews_btn-prev">
            <Image width={18} height={18} src={arrow} alt="arrow" />
          </button>
          <button type="button" id="reviews_btn-next">
            <Image width={18} height={18} src={arrow} alt="arrow" />
          </button>
        </SC.SliderButtonsWrapper>
      </SC.Title>
      <SC.SliderWrapper>
        <Swiper
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={16}
          pagination={{
            // uncomment when added more than 6 reviews
            // dynamicBullets: true,
            clickable: true,
          }}
          navigation={{
            enabled: true,
            nextEl: '#reviews_btn-next',
            prevEl: '#reviews_btn-prev',
            disabledClass: 'disabled',
          }}
          allowTouchMove={isMobile}
          modules={[Navigation, Pagination]}
          className="slick-slider"
        >
          {reviewsList.map(({ review, username, img }) => (
            <SwiperSlide key={`${username}`}>
              <div style={{ height: '100%' }}>
                <SC.Review>
                  <SC.ReviewTitle>
                    <SC.ReviewTitleImageWrapper>
                      <Image width={48} height={48} src={img} alt="username" />
                    </SC.ReviewTitleImageWrapper>
                    {username}
                  </SC.ReviewTitle>
                  <SC.ReviewText>{review}</SC.ReviewText>
                </SC.Review>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </SC.SliderWrapper>
    </SC.Wrapper>
  );
}

export default Reviews;
