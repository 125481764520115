'use client';

import React from 'react';
import Image from 'next/image';
import image from '@/public/staticfiles/download/header-image.png';
import android from '@/public/staticfiles/download/android-jb-apk.svg';
import iosJB from '@/public/staticfiles/download/ios-jb.svg';
import { useDeviceInfoContext } from '@/processes/contexts/device-info';
import { buildTelegramLinkWithUserDeviceType } from '@/shared/lib/link-formatters';
import { DownloadAppButtons } from '@/entities/download-app-buttons';
import { Button } from '@/shared/ui/button';
import * as SC from '../styled';

function DownloadAppBlock() {
  const { type } = useDeviceInfoContext();

  return (
    <SC.Wrapper id="download-app-block">
      <div>
        <SC.BlockTitle>Воспользуйтесь новейшими технологиями для обмена сообщениями!</SC.BlockTitle>
        <DownloadAppButtons
          appstoreLink="https://nicegramap.onelink.me/gn7C/ybynsm7h"
          gplayLink="https://nicegramap.onelink.me/gn7C/ybynsm7h"
        />
      </div>

      <SC.ImagesWrapper>
        <Image width={600} height={600} src={image} alt="" />
      </SC.ImagesWrapper>
    </SC.Wrapper>
  );
}

export default DownloadAppBlock;
