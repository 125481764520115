'use client';

import React from 'react';
import Link from 'next/link';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID } from '@/processes/contexts/analytics/constants';

import * as SC from '../styled';

function FaqBlock() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent, sendSafeGtagEvent } =
    useAnalyticsContext();

  return (
    <SC.Wrapper>
      <SC.BlockTitle>FAQ</SC.BlockTitle>
      <SC.QuestionsList>
        <SC.QuestionItem>
          <SC.QuestionItemTitle>Как я могу получить Nicegram для macOS?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Nicegram доступен для macOS Apple M1 и более новых версий. -{'>'}{' '}
            <Link
              onClick={() => {
                sendSafeFbqEvent('Lead');
                sendSafeXEvent(X_LEAD_EVENT_ID);
                sendSafeYMEvent('app_store_click');
                sendSafeGtagEvent('app_store_click');
                sendSafeMixpanelEvent('track', 'app_store_click');
              }}
              target="_blank"
              rel="noreferrer"
              href="https://nicegramap.onelink.me/gn7C/ybynsm7h"
            >
              Скачать
            </Link>
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>Как я могу получить Nicegram для Android?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Прежде Nicegram был доступен только для iOS, но в 2021 году мы представили версию Nicegram для Android.
            Первое время некоторые функции могут быть недоступны, но воспользоваться основными преимуществами приложения
            можно уже сейчас.{' '}
            <Link
              onClick={() => {
                sendSafeFbqEvent('Lead');
                sendSafeXEvent(X_LEAD_EVENT_ID);
                sendSafeYMEvent('app_store_click');
                sendSafeGtagEvent('app_store_click');
                sendSafeMixpanelEvent('track', 'app_store_click');
              }}
              target="_blank"
              rel="noreferrer"
              href="https://nicegramap.onelink.me/gn7C/ybynsm7h"
            >
              Скачать в Play Store
            </Link>
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>Почему я не могу присоединиться к бета-тестированию TestFlight?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            В бета-тестах TestFlight может участвовать до 10 000 тестировщиков. Вероятно, все слоты бета-тестирования в
            настоящее время заняты. Если через несколько дней новые слоты не откроются, напишите, пожалуйста, в чат
            Nicegram, что слоты бета-тестирования TestFlight заполнены, но вы хотели бы присоединиться. Разработчик
            удалит бета-тестеров, которые давно не обновляли приложение, чтобы открыть доступ для новых тестировщиков.
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>Почему меня исключили из бета-тестирования TestFlight?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Слоты бета-тестирования для Nicegram пользуются большим спросом. Если вы давно не обновляли свою бета-версию
            Nicegram, разработчик удалит вас из списка, чтобы открыть возможность для участия в бета-тестировании другим
            тестировщикам.
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>
            Почему в обновлении Nicegram отсутствуют новейшие функции Telegram?
          </SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Nicegram главным образом является копией официального приложения Telegram. Однако разработчики официального
            приложения часто меняют свою версию так, что полезные функции Nicegram перестают работать. Мы не думаем, что
            они делают это намеренно, разработка приложений — сложная задача. Наш разработчик делает все возможное,
            чтобы новые изменения в официальном приложении Telegram были быстро интегрированы в Nicegram. Пожалуйста,
            подождите немного.
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
      </SC.QuestionsList>
    </SC.Wrapper>
  );
}

export default FaqBlock;
