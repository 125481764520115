'use client';

import React from 'react';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID, X_PAGE_VIEW_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { CtaButtonsModel } from '@/entities/cta-buttons';
import { Button } from '@/shared/ui/button';

import { Wrapper } from './styled';

function DownloadHeader() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent } = useAnalyticsContext();

  React.useEffect(() => {
    sendSafeXEvent(X_PAGE_VIEW_EVENT_ID);
  }, []);

  return (
    <Wrapper>
      <h1>Nicegram: Раскройте все возможности вашего Telegram-аккаунта.</h1>
      <p>
        Воспользуйтесь новейшими технологиями для обмена сообщениями — несколько аккаунтов, интеграция с ИИ и высокий
        уровень конфиденциальности
      </p>
      <Button
        id={CtaButtonsModel.constants.HEADER_CTA_BUTTON_ID}
        as="a"
        fullWidth
        href="https://nicegramap.onelink.me/gn7C/ybynsm7h"
        rel="noreferrer"
        target="_blank"
        onClick={() => {
          sendSafeFbqEvent('Lead');
          sendSafeXEvent(X_LEAD_EVENT_ID);
          sendSafeFbqEvent('Lead');
          sendSafeYMEvent('app_store_click');
          sendSafeMixpanelEvent('track', 'app_store_click');
        }}
        gradient
        label={'Скачать'}
      />
    </Wrapper>
  );
}

export default DownloadHeader;
