'use client';

import React from 'react';
import Image from 'next/image';
import bg from '@/public/staticfiles/bannerbg-min.webp';
import appleIcon from '@/public/staticfiles/apple-icon.svg';
import googlePlayIcon from '@/public/staticfiles/google-play-icon.svg';
import logo from '@/public/staticfiles/logo/logo_short_white.svg';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { Container } from '@/shared/ui/container';
import { Button } from '@/shared/ui/button';
import { Title, Text, ButtonsWrapper, Content, Wrapper } from './styled';
import { X_LEAD_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { APPSTORE_LINK, GPLAY_LINK } from '@/shared/constants';
import { useOneLinkContext } from '@/processes/contexts/one-link';

function AppBanner() {
  const { sendSafeXEvent, sendSafeYMEvent, sendSafeGtagEvent, sendSafeFbqEvent, sendSafeMixpanelEvent } =
    useAnalyticsContext();
  const { href } = useOneLinkContext();

  return (
    <Wrapper>
      <Image src={bg} alt="bg" width={400} height={200} />
      <Container>
        <Image height={56} src={logo} alt="logo" />
        <Content>
          <Title>Get the Nicegram app now!</Title>
          <Text>The most trusted & secure crypto wallet</Text>
        </Content>
        <ButtonsWrapper>
          <Button
            as="a"
            target="_blank"
            rel="noreferrer nofollow"
            href={href}
            onClick={() => {
              sendSafeFbqEvent('Lead');
              sendSafeXEvent(X_LEAD_EVENT_ID);
              sendSafeMixpanelEvent('track', 'app_store_click');
              sendSafeGtagEvent('app_store_click');
              sendSafeYMEvent('app_store_click');
            }}
            label={<Image width={100} height={26} src={appleIcon} alt="apple logo" />}
          />
          <Button
            as="a"
            target="_blank"
            rel="noreferrer nofollow"
            href={href}
            onClick={() => {
              sendSafeFbqEvent('Lead');
              sendSafeXEvent(X_LEAD_EVENT_ID);
              sendSafeMixpanelEvent('track', 'app_store_click');
              sendSafeGtagEvent('app_store_click');
              sendSafeYMEvent('app_store_click');
            }}
            label={<Image width={110} height={26} src={googlePlayIcon} alt="gplay logo" />}
          />
        </ButtonsWrapper>
      </Container>
    </Wrapper>
  );
}

export { AppBanner };
