'use client';

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { CtaButtonsModel } from '@/entities/cta-buttons';
import { Logo } from '@/entities/logo';
import { useInView } from '@/shared/hooks';
import { Button } from '@/shared/ui/button';
import { navbarUnpinCallback, navbarPinCallback, hooks } from '../../lib';
import { useOneLinkContext } from '@/processes/contexts/one-link';

import * as SC from '../styled';

function Navbar() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeYMEvent, sendSafeGtagEvent } = useAnalyticsContext();
  const { href } = useOneLinkContext();

  const navbarRef = React.useRef<HTMLDivElement | null>(null);
  const isHeaderInView = useInView(CtaButtonsModel.constants.HEADER_CTA_BUTTON_ID, {
    defaultValue: true,
  });

  hooks.useScroll({
    ref: navbarRef,
    thresholdExceedCallback: () => navbarPinCallback(navbarRef.current),
    thresholdReturnCallback: () => navbarUnpinCallback(navbarRef.current),
  });

  return (
    <SC.Wrapper ref={navbarRef}>
      <div>
        <Logo />
      </div>

      {!isHeaderInView && (
        <AnimatePresence>
          <Button
            style={{ maxWidth: 300 }}
            size="sm"
            fullWidth
            animate={{ scale: 1 }}
            initial={{ scale: 0 }}
            exit={{ scale: 0 }}
            variant="gradient-glow"
            label="Скачать"
            as="a"
            href={href}
            target="_blank"
            onClick={() => {
              sendSafeFbqEvent('Lead');
              sendSafeGtagEvent('app_store_click');
              sendSafeYMEvent('app_store_click');
              sendSafeMixpanelEvent('track', 'app_store_click');
            }}
          />
        </AnimatePresence>
      )}
      <div />
    </SC.Wrapper>
  );
}

export default Navbar;
